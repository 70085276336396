.marg-left-10 {
    margin-left: 10px;
}

.marg-right-10 {
    margin-right: 10px;
}


.kt-header {
	box-shadow: 0 25px 50px 0 rgba(0,0,0,0.04);
	border: none;

	.kt-header-menu {

		.kt-menu__nav {

			> .kt-menu__item {

				> .kt-menu__link {

					> .kt-menu__link-text {

						> .menu-icon {
							margin-right: 5px;
						}
					}
				}

				&.kt-menu__item--active {

					> .kt-menu__link {
						height: 36px;
						-webkit-border-radius: 8px;
						-moz-border-radius: 8px;
						border-radius: 8px;

						@media only screen and (max-width: 1024px) {
							-webkit-border-radius: 0;
							-moz-border-radius: 0;
							border-radius: 0;
							height: 44px;
						}

						.kt-menu__link-text {
							font-weight: 500;
							text-shadow: -0.06ex 0 #5C66FF, 0.06ex 0 #5C66FF;
							.menu-icon {
								text-shadow: none!important;
							}
						}
					}
				}

				&:hover {

					> .kt-menu__link {
						height: 36px;
						-webkit-border-radius: 8px;
						-moz-border-radius: 8px;
						border-radius: 8px;

						@media only screen and (max-width: 1024px) {
							-webkit-border-radius: 0;
							-moz-border-radius: 0;
							border-radius: 0;
							height: 44px;
						}

						.kt-menu__link-text {
							font-weight: 500;
							text-shadow: -0.06ex 0 #5C66FF, 0.06ex 0 #5C66FF;
							.menu-icon {
								text-shadow: none!important;
							}
						}
					}
				}
			}
		}
	}

	.kt-header__topbar {
		.kt-header__topbar-item {
			&.kt-header__topbar-item--user {
				.kt-header__topbar-user {
					.kt-badge.kt-badge--username {
						width: 24px;
						height: 24px;
						font-size: 1rem;
						border-radius: 24px;
						margin-right: .55rem;
						background-color: #E8EAED;
						color: #9FAABB;
					}

					.kt-header__topbar-username {
						font-size: 16px;
						font-weight: 600;
						padding: 0 .75rem 0 0;
					}

					&::after {
						font-family: 'Font Awesome\ 5 Free';
						content: "\f0d7";
						font-weight: 900;
					}
				}
			}
		}
	}
}

.kt-notification__item {
	&.dropdown-toggle {
		@media only screen and (max-width: 375px) {
			white-space: normal!important;
		}
	}
}

.kt-header-mobile {
	.kt-header__topbar-item {
		cursor: pointer;

		&.kt-header__topbar-item--user {
			.kt-header__topbar-user {
				.kt-badge.kt-badge--username {
					width: 24px;
					height: 24px;
					font-size: 1rem;
					border-radius: 24px;
					margin-right: .55rem;
					background-color: #E8EAED;
					color: #9FAABB;

					@media only screen and (max-width: 768px) {
						width: 28px;
						height: 28px;
						font-size: 1.1rem;
						border-radius: 28px;
					}
				}

				.kt-header__topbar-username {
					font-size: 16px;
					font-weight: 600;
					padding: 0 .75rem 0 0;
					vertical-align: middle;
				}

				&::after {
					font-family: 'Font Awesome\ 5 Free';
					content: "\f0d7";
					font-weight: 900;
				}
			}
		}
	}
}

.kt-subheader {
	height: 75px !important;
	border-radius: 0 0 16px 16px;
	box-shadow: 0 10px 50px 0 rgba(0,0,0,0.04);
	position: relative !important;

	@media only screen and (max-width: 1024px) {
		min-height: 55px !important;
		height: auto !important;
	}


	@media only screen and (max-width: 768px) {
		border-radius: 0;
		margin: 0 -5px;
	}

	.kt-subheader__breadcrumbs a {
		cursor: pointer;
	}


	.kt-subheader__main {

		.kt-subheader__title {
			display: block;
			width: 100%;
			font-size: 24px !important;
			font-weight: 700;

			@media only screen and (max-width: 1024px) {
				font-size: 18px !important;
			}
		}

		.kt-subheader__desc {
			display: block;
			width: 100%;
		}
	}

	.kt-subheader__toolbar {

		.kt-subheader__wrapper {
			.dropdown {
				height: 40px;
				border: 1px solid;
				border-radius: 6px;
				box-shadow: 0 2px 6px 0;

				@media only screen and (max-width: 1024px) {
					height: 36px;

					> button {
						margin-top: .1rem;
					}
				}

				> button {
					background-color: transparent !important;

					&.dropdown-toggle {
						&::after {
							display: inline-block !important;
							font-family: 'Font Awesome\ 5 Free';
							content: "\f0d7";
							font-weight: 900;
							font-size: 0.95rem;
						}
					}
				}
			}
		}
	}
}

.kt-content {
	.kt-portlet {
		background-color: transparent;
		box-shadow: none;
		border-radius: 12px;

		.loading-banner {
			top: 60px;

			@media only screen and (max-width: 768px) {
				top: 55px;
			}
		}

		.kt-portlet__head {
			background-color: transparent;
			border: none;
			z-index: 3;

			.nav-tabs {
				&.nav-tabs-line {
						.nav-item {
						a.nav-link {

							&.active,
							&:hover {
								border-bottom: 3px;
								}
						}
					}

					&.nav.nav-tabs {
							.nav-item {
							.nav-link {

								&.active,
								&:hover {
									border-bottom: 3px solid;
									}
							}
						}
					}
				}
			}

			.kt-portlet__head-toolbar {
				.dropdown {
					border-radius: 8px;

					&.show {
						> .btn-secondary.dropdown-toggle {
							background-color: transparent !important;
						}
					}

					.btn-secondary {
						border-radius: 8px;

						&:not(:disabled):not(.disabled).active,
						&:not(:disabled):not(.disabled):active {
							background-color: transparent !important;
						}

						&:hover {
							background-color: transparent !important;
						}

						&:focus {
							background-color: transparent !important;
						}
					}

					.btn-sm {
						border-radius: 8px;
					}

					> button {
						font-weight: 500;

						&::after {
							font-family: 'Font Awesome\ 5 Free';
							content: "\f0d7";
							font-weight: 900;
							font-size: 0.95rem;
						}
					}
				}

				.mobiscroll-elem {
					border: 0;

					&:hover {
						background-color: transparent;
						border: 0;
					}

					&:focus {
						background-color: transparent;
						border: 0;
					}

					.update-picker {
						border: solid 1px #e2e5ec;
						font-weight: 500;
						border-radius: 8px;
					}
				}
			}
		}

		.kt-portlet__body {
			border-radius: 12px;
		}
	}
}

.dropdown-menu {
	&.dropdown-menu-xl {
		@media screen and (max-width: 375px) {
			width: 250px!important;
		}
	}		
}

.mat-icon-button {
	&.responsive-mat-button {
		@media screen and (max-width: 350px) {
			width: 30px!important;
			height: 30px!important;
			line-height: 30px!important;
		}
	}
}

.mat-table {
	tr {
		&.mat-row {
			@media screen and (max-width: 599px) {
				height: 40px!important;
			}
		}
	}
}

.kt-app__aside-close {
	@media screen and (max-width: 1024px) {
		right: 0!important;
	}
}

.content-load {
	-webkit-animation: content-load-animation 0.5s ease-in-out;
	-moz-animation: content-load-animation 0.5s ease-in-out;
	-o-animation: content-load-animation 0.5s ease-in-out;
	animation: content-load-animation 0.5s ease-in-out;
}

.modal-content {
	-webkit-animation: modal-fade 0.5s ease-in-out;
	-moz-animation: modal-fade 0.5s ease-in-out;
	-o-animation: modal-fade 0.5s ease-in-out;
	animation: modal-fade 0.5s ease-in-out;
}

@keyframes modal-fade {
	0% {
		opacity: 0;
		transform: translateY(-5%)
	}

	100% {
		opacity: 1;
		transform: translateY(0)
	}
}
@keyframes content-load-animation {
	from {
		transform: translateY(-3%);
		opacity: 0;
	}

	to {
		transform: translateY(0);
		opacity: 1;
	}
}

.mbsc-fr {
	z-index: 999999!important;
}

.fade-in-fast {
	-webkit-animation: fadeIn 0.4s;
	-moz-animation: fadeIn 0.4s;
	-o-animation: fadeIn 0.4s;
	animation: fadeIn 0.4s;
}

.fade-in {
	-webkit-animation: fadeIn 1s;
	-moz-animation: fadeIn 1s;
	-o-animation: fadeIn 1s;
	animation: fadeIn 1s;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}


.fc-daygrid {
	table {
		thead {
			tr {
				th {
					background-color: transparent !important;
				}
			}
		}

		tbody {
			tr {
				td {
					.fc-daygrid-day-events {
						a {
							&.clickable-event {
								border-width: 0 6px;
								margin:0 4px;

								&.holiday-event {
									background-color: transparent !important;
									text-overflow: ellipsis;
									overflow: hidden;
									display: block;
									padding: 2px 5px;
								}

								.fc-title {
									margin-left: 4px;
									font-size: .9rem;
									font-weight: 600;
								}

								.fc-event-main {
									padding: 2px 5px;
									text-overflow: ellipsis;
									overflow: hidden;
								}
							}
						}
					}
				}
			}
		}
	}
}
