/* You can add global styles to this file, and also import other style files */
@import "assets/vendors/global/vendors.bundle.css";
@import "assets/vendors/daterangepicker/daterangepicker.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "assets/vendors/heatmap/d3.css";
@import "assets/css/custom-layout.scss";
@import "assets/css/light-theme.scss";
@import "assets/css/dark-theme.scss";
@import "assets/css/blue-theme.scss";
@import "assets/css/purple-theme.scss";
@import "assets/css/yellow-theme.scss";

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
	display: none !important;
}


.mat-table__wrapper--dashboard .mat-table {
	min-width: 600px!important;
	width: 100% !important;
}

.position-static {
	position: static;
}

.dots {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.striped-bg {
    background: repeating-linear-gradient(45deg,#fafafa,#fafafa 10px,#fff 10px,#fff 20px)!important;
}

.fc-content {
	padding: .07rem .55rem .07rem 2rem !important;
}

.fc-unthemed .fc-event .fc-content:before, 
.fc-unthemed .fc-event-dot .fc-content:before {
	top: 5px!important;
	height: 7px!important;
	width: 7px!important;
}
.fc-day-grid-event.fc-start {
	border-left: solid 4px rgba(0, 0, 0, 0.3) !important;
}

.fc-day-grid-event.fc-end {
	border-right: solid 4px rgba(0, 0, 0, 0.2) !important;
}
.fc-day-grid-event .fc-content {
	text-overflow:ellipsis!important;
}
.clickable-event {
	cursor: pointer;
}

.fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-list-heading td, .fc-unthemed .fc-list-view, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
	border-color: #D8D8D8!important;
}

.fc-day-grid-event{
	&.holiday-event {
		background-color: transparent!important;
		-webkit-box-shadow: none!important;
		-moz-box-shadow: none!important;
		box-shadow: none!important;
		border-right: 0!important;
		border-left: 0!important;
		border: 0!important;

		.fc-title {
			font-weight: 600!important;
		}
	}
}

.cell-accepted {
	background: #5867dd !important;

	.fc-title {
		color: white !important;
	}

	.fc-content {
		color: white !important;
	}

	&:hover {
		background: #6a77dd !important;
	}
}

.cell-declined {
	background: #fd397a !important;

	.fc-title {
		color: white !important;
	}

	.fc-content {
		color: white !important;
	}

	&:hover {
		background: #ff588f !important;
	}
}

.cell-suggested {
	background: #EDEDEF !important;

	&:hover {
		background: #d5d5d5 !important;
	}
}
.fc-unthemed .fc-event.fc-start .fc-content:before{
	display:none!important;
}
.fc-unthemed .fc-event .fc-content{
	padding-left:5px!important;
}

.fc-unthemed th.fc-day-header > a, .fc-unthemed th.fc-day-header > span, fc-day-number {
	color: #828282!important;
}
