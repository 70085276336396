$header-bg-color: #222222;
$header-shadow-color: rgba(0,0,0,0.04);
$header-border-color: #141b30;
$header-topbar-border-color: #0a0d19;
$menu-text-color: #c2c2c2;
$menu-active-text-color: #dbdbdb;
$menu-hover-text-color: #5d78ff;
$menu-active-bg-color: rgba(108, 108, 108, 0.24);
$menu-hover-bg-color: rgba(108, 108, 108, 0.40);
$topbar-hover-bg-color: rgba(173, 173, 173, 0.15);
$topbar-username-color: #c2c2c2;
$topbar-username-badge-color: #9FAABB;
$topbar-username-badge-bg-color: #E8EAED;
$subheader-bg-color: #2a2a2a;
$subheader-text-color: #e0e0e0;
$subheader-dropdown-bg-color: #757373;
$subheader-dropdown-hover-bg-color: #929292;
$subheader-dropdown-shadow-color: rgba(0,0,0,0.04);
$subheader-dropdown-text-color: #e0e0e0;
$portlet-head-bg-color: #181f34;
$portlet-head-tab-text-color: #c2c2c2;
$portlet-head-tab-active-text-color: #5C66FF;
$portlet-head-dropdown-bg-color: #757373;
$portlet-head-dropdown-hover-bg-color: #9d9d9d;
$portlet-head-dropdown-shadow-color: rgba(0,0,0,0.04);
$portlet-head-dropdown-text-color: #e0e0e0;
$portlet-body-bg-color: #2a2a2a;
$portlet-body-hover-bg-color:#525252;
$portlet-body-text-color: #c2c2c2;
$portlet-body-text-color-secondary: #c2c2c2;
$footer-bg-color: #000000;
$footer-text-color: #e0e0e0;
$chart-label-color: #6693d7;
$filter-toogle-color: #f6f6f6;
$filter-wapper-bg-color: #222;
$filter-section-border-color: #484848;
$filter-heading-bg-color: #2a2a2a;
$filter-heading-color: #e0e0e0;
$filter-tagline-color: #867f79;
$filter-section-bg-color: #222;
$filter-section-title-color: #434349;
$filter-section-text-color: #e0e0e0;
$filter-section-input-bg-color: #fff;
$filter-section-input-text-color: #cacaca;
$filter-section-input-border-color: #878787;
$filter-apply-button-bg-color: #5C66FF;
$filter-apply-button-text-color: #fff;
$filter-footer-bg-color: #222;
$section-title-color: #c2c2c2;
$mobile-menu-close-btn-bg-color: #343434;
$mobile-menu-close-btn-icon-color: #968ad4;
$mat-table-bg-color: #2a2a2a;
$mat-table-text-color: rgba(255,255,255,0.75);
$mat-table-border-color: rgba(255,255,255,0.2);
$mat-table-th-bg-color: rgba(0,0,0,.30);
$mat-table-th-text-color: rgba(255,255,255,0.75);
$mat-table-th-border-color: rgba(255,255,255,0.2);
$mat-table-bg-hover-color: rgba(0,0,0,0.30);
$mat-paginate-text-color: rgba(255,255,255,0.75);
$form-lable-color: #fff;
$form-field-text: rgba(255,255,255,0.75);
$form-field-border-color: rgba(255,255,255,0.2);
$sidebar-link-active-bg-color: rgba(255,255,255,0.08);
$sidebar-link-hover-bg-color: rgba(255,255,255,0.08);
$badge-custom-bg-color: rgba(0,0,0,0.2);
$badge-custom-text-color: rgba(255,255,255,0.75);
$group-btn-bg-color: transparent;
$group-btn-active-bg-color: rgba(210,210,210,0.35);
$group-btn-hover-bg-color: rgba(210,210,210,0.15);
$group-btn-text-color: #e0e0e0;
$group-btn-border-color: #878787;
$form-control-bg-color: #757373;
$form-control-border-color: #757373;
$form-control-text-color: #FFF;
$form-control-placeholder-color: #e0e0e0;
$form-control-icon-color: rgba(255,255,255,0.5);
$card-bg-color: rgba(255,255,255,0.08);
$card-header-bg-color: rgba(0,0,0,0.05);
$card-header-text-color: #dbdbdb;
$card-body-text-color: #a9a9a9;
$card-big-text-color: #dbdbdb;
$card-icon-color: rgba(255, 255, 255, 0.3);
$card-border-color: rgba(255,255,255,0.07);
$outline-btn-border-color: #5d78ff;
$outline-btn-text-color: #5d78ff;
$outline-btn-hover-color: #5d78ff;
$outline-btn-hover-text-color: #fff;
$camp-card-bg-color: rgba(255, 255, 255, 0.08);
$camp-card-title-color: #dbdbdb;
$camp-card-text-color: #dbdbdb;
$stepper-header-label-color:#a9a9a9;
$stepper-header-label-active-color: #5d78ff;
$stepper-header-step-icon-color: rgba(255,255,255,0.25);
$stepper-header-step-icon-edit-color: #5d78ff;
$stepper-body-form-label-color: #dbdbdb;
$stepper-body-form-field-input-color: #dbdbdb;
$stepper-body-form-field-bg-color: rgba(255,255,255,0.1);
$stepper-body-form-field-outline-color: #7b7b7b;
$stepper-body-form-checkbox-outline-color: #7b7b7b;
$stepper-body-form-select-arrow-color: #a9a9a9;
$stepper-body-form-hint-color: #a9a9a9;
$template-card-bg-color: rgba(255, 255, 255, 0.08);
$template-card-img-bg-color: #2a2a2a;
$template-card-title-color: #dbdbdb;
$panel-heading-bg-color: #232323;
$guest-details-default-text-color: #c2c2c2;
$block-titles-text-color: #e0e0e0;
$guest-details-icon-light-color: #7a7e92;
$guest-details-card-bg: rgba(255,255,255,0.1);
$guest-details-card-label-color: #AAB2C6;
$guest-details-card-text-color: #AAB2C6;
$guest-details-card-border-color: rgba(255,255,255,0.1);
$guest-details-block-border-color: rgba(0, 0, 0, 0.1);
$guest-details-block-data-color: #ffffff;
$guest-details-badge-bg-color: rgba(255,255,255,0.75);
$guest-details-badge-text-color: #000;
$guest-list-city-color: #c2c2c2;
$guest-list-bookingref-color: #d4d4d4;
$mat-select-dropdown-bg-color: #404040;
$sub-title-color: #d4d4d4;
$mat-primary-button-border-color: #5d78ff;
$mat-primary-button-text-color: #5d78ff;
$mat-primary-button-hover-bg-color: #5d78ff;
$mat-primary-button-hover-text-color: #fff;
$event-table-border-color: rgba(255, 255, 255, 0.2);
$event-calendar-date-color: #c2c2c2;
$event-calendar-clickable-event-bg-color: #222222;
$event-calendar-clickable-event-bg-hover-color: #444343;
$event-calendar-clickable-event-border-color: #1f1f1f;
$event-calendar-clickable-event-border-corder-color: rgba(0, 0, 0, 0.2);
$event-calendar-event-title-color: #c2c2c2;
$event-nextprev-btn-text-color: #e0e0e0;
$event-nextprev-btn-bg-color: #757373;
$event-nextprev-btn-hover-bg-color: #929292;
$event-nextprev-btn-border-color: transparent;
$event-calendar-today-color: #f3f3f31e;
$campaign-credit-text-color: #a7abc3;
$heatmap-paginator-text-color: #bababa;

body {
    &.dark-theme {
        background-color: #1d1d1d;
    }
}

.dark-theme {

	::placeholder {
		color: $form-control-placeholder-color !important;
		opacity: 1;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $form-control-placeholder-color !important;
		opacity: 1;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $form-control-placeholder-color !important;
		opacity: 1;
	}

	.kt-header {
		border-bottom-color: $header-border-color;
		background-color: $header-bg-color;
		box-shadow: 0 25px 50px 0 $header-shadow-color;

		.kt-header-menu-wrapper-close {
			background-color: $mobile-menu-close-btn-bg-color;

			> i {
				color: $mobile-menu-close-btn-icon-color;
			}
		}

		.kt-header-menu-wrapper {
			@media only screen and (max-width: 1024px) {
				background-color: $header-bg-color;

				.kt-menu__nav {
					> .kt-menu__item {
						&:not(.kt-menu__item--parent) {
							&:not(.kt-menu__item--open) {
								&:not(.kt-menu__item--here) {
									&:not(.kt-menu__item--active) {
										&:hover {
											> .kt-menu__heading {
												background-color: $menu-active-bg-color;
											}

											> .kt-menu__link {
												background-color: $menu-active-bg-color;
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.kt-header-menu {

				&.kt-header-menu-mobile {
					background-color: $header-bg-color;
				}

				.kt-menu__nav {

					> .kt-menu__item {

						> .kt-menu__link {

							> .kt-menu__link-text {
								color: $menu-text-color;

								&.copilot {
									color: $menu-active-text-color;
								}
							}
						}

						&.kt-menu__item--active {

							> .kt-menu__link {
								background-color: $menu-active-bg-color;

								.kt-menu__link-text {
									color: $menu-active-text-color;
									text-shadow: -0.06ex 0 $menu-active-text-color, 0.06ex 0 $menu-active-text-color;
								}
							}
						}

						&:hover {

							> .kt-menu__link {
								background-color: $menu-hover-bg-color;

								.kt-menu__link-text {
									color: $menu-hover-text-color;
									text-shadow: -0.06ex 0 $menu-hover-text-color, 0.06ex 0 $menu-hover-text-color;
								}
							}
						}
					}
				}
			}
		}

		.kt-header__topbar {
			.kt-header__topbar-item {
				&.kt-header__topbar-item--user {
					.kt-header__topbar-user {
						.kt-badge.kt-badge--username {
							background-color: $topbar-username-badge-bg-color;
							color: $topbar-username-badge-color;
						}

						.kt-header__topbar-username {
							color: $topbar-username-color !important;
						}

						&::after {
							color: $topbar-username-color !important;
						}
					}
				}

				.kt-header__topbar-user {
					padding: 5px 8px;

					&:hover {
						background-color: $topbar-hover-bg-color !important;
					}
				}
			}
		}
	}

	.kt-header-mobile {
		background-color: $header-bg-color;
	}

	.kt-subheader {
		background-color: $subheader-bg-color !important;

		.kt-subheader__title {
			color: $subheader-text-color !important;
		}

		.kt-subheader__desc {
			color: $subheader-text-color !important;
		}

		.kt-subheader__breadcrumbs a {
			color: #8b9df6 !important;
			font-weight: bold;

			&:hover {
				color: #5d78ff !important;
			}
		}

		.kt-subheader__breadcrumbs-home:hover {
			color: $menu-hover-text-color !important;
		}

		.kt-subheader__toolbar {

			.kt-subheader__wrapper {

				.dropdown {
					border: none;
					box-shadow: 0 2px 6px 0 $subheader-dropdown-shadow-color;
					background-color: $subheader-dropdown-bg-color;

					@media (hover: hover) {
						&:hover {
							background-color: $subheader-dropdown-hover-bg-color;
						}
					}

					> button {
						color: $subheader-dropdown-text-color;

						> span {
							color: $subheader-dropdown-text-color;
						}

						> .flaticon2-calendar-1 {
							color: $subheader-dropdown-text-color;
						}

						i {
							color: $filter-section-input-text-color !important;
						}
					}

					.form-control {
						background-color: #fff !important;
						border-color: #e2e5ec !important;
						color: #495057 !important;

						&:focus {
							border-color: #9aabff !important;
						}
					}
				}
			}

			.kt-subheader__btn-mobile-filter {
				color: $filter-toogle-color !important;

				@media (hover: hover) {
					&:hover {
						background-color: $topbar-hover-bg-color !important;
					}
				}
			}

			.form-control {
				background-color: $form-control-bg-color !important;
				border-color: $form-control-border-color !important;
				color: $form-control-text-color !important;
			}

			.search-icon {
				.flaticon2-search-1 {
					color: $form-control-icon-color !important;
				}
			}

			.clear-icon {
				.flaticon2-cross {
					color: $form-control-icon-color !important;
				}
			}

			.btn {
				&.btn-prev, &.btn-nxt {
					background-color: $event-nextprev-btn-bg-color !important;
					color: $event-nextprev-btn-text-color !important;
					border-color: $event-nextprev-btn-border-color !important;

					&:hover {
						background-color: $event-nextprev-btn-hover-bg-color !important;
					}
				}
			}
		}
	}

	.mobile-filter-wrapper {
		background-color: #1d1d1d !important;

		.mobile-back:hover {
			background-color: $topbar-hover-bg-color !important;
		}

		.kt-subheader__main {
			background-color: $subheader-bg-color !important;

			.kt-subheader__title {
				color: $subheader-text-color !important;
			}

			h4 {
				color: $filter-tagline-color !important;
			}

			.fas {
				color: $filter-heading-color !important;
			}
		}

		.filter-section {
			border-bottom-color: $filter-section-border-color !important;
			background-color: $filter-section-bg-color !important;

			h3 {
				color: $filter-section-text-color !important;
			}

			.filter-group {
				label {
					color: $filter-section-text-color !important;
				}

				.kt-subheader__btn-secondary {
					color: $filter-section-input-text-color !important;
				}

				.kt-subheader__btn-daterange {
					.kt-subheader__btn-daterange-date {
						color: $filter-section-input-text-color !important;
					}

					i {
						color: $filter-section-input-text-color !important;
					}
				}

				.dropdown {
					border-color: $filter-section-input-border-color !important;

					.btn-secondary {
						color: $filter-section-input-text-color !important;
						border-color: $filter-section-input-border-color !important;
					}
				}

				.mobiscroll-elem {
					border-color: $filter-section-input-border-color !important;
					color: $filter-section-input-text-color !important;

					.update-picker {
						color: $filter-section-input-text-color !important;
					}

					i {
						color: $filter-section-input-text-color !important;
					}

					&::after {
						color: $filter-section-input-text-color !important;
					}
				}

				.select-container {
					select {
						color: $filter-section-input-text-color !important;
					}
				}
			}
		}

		.filter-footer {
			background-color: $filter-footer-bg-color !important;
			box-shadow: #7a7a7a 0px 0 8px 0px !important;
		}

		.btn-secondary.active, .btn-secondary:active, .btn-secondary:focus, .btn-secondary:hover, .show > .btn-secondary.dropdown-toggle, .mobiscroll-elem:hover {
			color: $filter-section-input-text-color !important;
			border-color: $filter-section-input-border-color !important;
			background-color: $topbar-hover-bg-color !important;
		}
	}


	.kt-content {
		.kt-portlet {
			.kt-portlet__head {
				.nav-tabs {
					&.nav-tabs-line {
						a.nav-link {
							color: $portlet-head-tab-text-color;

							&.active, &:hover {
								color: $portlet-head-tab-active-text-color;
								text-shadow: -0.06ex 0 $portlet-head-tab-active-text-color, 0.06ex 0 $portlet-head-tab-active-text-color;
							}
						}

						&.nav.nav-tabs {
							.nav-link {
								color: $portlet-head-tab-text-color;

								&.active, &:hover {
									color: $portlet-head-tab-active-text-color;
									text-shadow: -0.06ex 0 $portlet-head-tab-active-text-color, 0.06ex 0 $portlet-head-tab-active-text-color;
								}
							}
						}
					}
				}

				.kt-portlet__head-toolbar {
					.dropdown {
						background-color: $portlet-head-dropdown-bg-color;
						box-shadow: 0 2px 6px 0 $portlet-head-dropdown-shadow-color;
						color: $portlet-head-dropdown-text-color !important;

						&:hover {
							background-color: $portlet-head-dropdown-hover-bg-color;
						}

						.btn-secondary {
							border: none;
						}

						> button {
							color: $portlet-head-dropdown-text-color;
						}

						.btn-outline-brand {
							border: 0 !important;
						}
					}

					.btn-group {
						> button:not(.active) {
							color: $portlet-head-dropdown-text-color;
						}

						> button:hover {
							color: #212529;
						}
					}

					.mobiscroll-elem {
						color: $portlet-head-dropdown-text-color;

						.update-picker {
							color: $portlet-head-dropdown-text-color;
							background-color: $portlet-head-dropdown-bg-color;
							border: none;
							box-shadow: 0 2px 6px 0 $portlet-head-dropdown-shadow-color;

							&:hover {
								background-color: $portlet-head-dropdown-hover-bg-color;
							}
						}

						&::after {
							color: $portlet-head-dropdown-text-color;
						}
					}

					.settings-dropdown, .share-dropdown {
						color: $filter-section-input-text-color !important;

						i {
							color: $filter-section-input-text-color !important;
						}

						&::after {
							color: $portlet-head-dropdown-text-color;
						}
					}
				}

				.kt-portlet__head-label {
					h3.kt-portlet__head-title {
						color: $section-title-color !important;
					}
				}
			}

			.kt-portlet__body {
				background-color: $portlet-body-bg-color;

				h3 {
					color: $section-title-color !important;
				}

				.table {
					color: $menu-text-color;

					.thead-light th {
						background-color: $portlet-body-bg-color !important;
						color: $menu-text-color !important;
					}

					&.table-sticky th {
						background-color: $portlet-body-bg-color !important;
						color: $menu-text-color !important;
					}
				}

				&.onKpiChartHover:hover, .kpi-content-hover:hover {
					background-color: $portlet-body-hover-bg-color;
					cursor: pointer;
				}

				.mat-table {
					thead {
						tr {
							background-color: $mat-table-th-bg-color !important;

							th {
								background-color: transparent !important;
							}
						}
					}

					tbody {
						tr {
							&:hover {
								background-color: $mat-table-bg-hover-color !important;
							}
						}
					}
				}

				.guest-table, .overview-guest-table {
					.mat-col-guest {
						.guest-name {
							small {
								&.guest-city {
									color: $guest-list-city-color !important;
								}

								&.guest-bookingref {
									color: $guest-list-bookingref-color !important;
								}
							}
						}
					}
				}

				.mat-paginator {
					.mat-select-value {
						color: $mat-paginate-text-color;
					}

					.mat-select-arrow {
						color: $mat-paginate-text-color;
					}
				}

				.guest-filter-options, .segment-search, .campaign-filter-options {

					.form-control {
						background-color: $form-control-bg-color !important;
						border-color: $form-control-border-color !important;
						color: $form-control-text-color !important;
					}

					.search-icon {
						.flaticon2-search-1 {
							color: $form-control-icon-color !important;
						}
					}

					.clear-icon {
						.flaticon2-cross {
							color: $form-control-icon-color !important;
						}
					}

					.btn-datepicker-trigger {
						background-color: $form-control-bg-color !important;
						border-color: $form-control-border-color !important;
						color: $form-control-text-color !important;

						.flaticon2-calendar-1 {
							color: $form-control-icon-color !important;
						}

						span.placeholder-text {
							color: $form-control-placeholder-color !important;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.top-data-info {
					color: $portlet-body-text-color-secondary !important;
				}

				.card {
					border-color: $card-border-color !important;
					background-color: $card-bg-color !important;

					.card-header {
						background-color: $card-header-bg-color;
						border-bottom-color: $card-border-color !important;

						h4 {
							color: $card-header-text-color !important;
						}
					}

					.card-body {
						color: $card-body-text-color !important;

						.fa {
							color: $card-icon-color !important;
						}

						.big-font {
							color: $card-big-text-color !important;
						}

						.card-property-list {
							border-top-color: $card-border-color !important;
							border-bottom-color: $card-border-color !important;
						}

						.exclude-bookings {
							border-top-color: $card-border-color !important;
						}
					}
				}

				.campaign-type-card {
					background-color: $camp-card-bg-color !important;

					.type-title {
						color: $camp-card-title-color !important;
					}

					p {
						color: $camp-card-text-color !important;
					}
				}

				.mat-table {
					background-color: $mat-table-bg-color;

					.mat-header-cell {
						background-color: transparent !important;
						color: $mat-table-th-text-color;
						border-bottom-color: $mat-table-th-border-color;
					}

					.mat-cell {
						color: $mat-table-text-color;
						border-bottom-color: $mat-table-border-color;
					}
				}

				mat-paginator {
					background-color: $portlet-body-bg-color !important;
					color: #bababa !important;

					.mat-icon-button[disabled][disabled] {
						color: rgba(186, 186, 186, 0.55) !important;
					}

					.mat-icon-button {
						color: #bababa !important;
					}
				}

				.no-results {
					color: $portlet-body-text-color;
				}

				.statistics-container {
					> span {
						color: $portlet-body-text-color;
					}
				}

				.nav-tabs {
					&.nav-tabs-line {
						a.nav-link {
							color: $portlet-head-tab-text-color;

							&.active, &:hover {
								color: $portlet-head-tab-active-text-color;
							}
						}

						&.nav.nav-tabs {
							.nav-link {
								color: $portlet-head-tab-text-color;

								&.active, &:hover {
									color: $portlet-head-tab-active-text-color;
								}
							}
						}
					}
				}

				.chart-title {
					.subtitle {
						color: $sub-title-color !important;
					}
				}

				.border-right {
					border-right-color: $mat-table-border-color !important;
				}

				.mat-stroked-button {
					border-color: $mat-primary-button-border-color !important;

					&.mat-primary {
						color: $mat-primary-button-text-color !important;
					}

					&:hover {
						background-color: $mat-primary-button-hover-bg-color !important;

						&.mat-primary {
							color: $mat-primary-button-hover-text-color !important;
						}
					}
				}

				.fc-scrollgrid {
					border-color: $event-table-border-color !important;
				}

				.fc-daygrid {
					table {
						thead {
							tr {

								table.fc-col-header {
									th {
										border-color: $event-table-border-color !important;
										color: $event-calendar-date-color !important;
									}
								}
							}
						}

						tbody {
							tr {
								td {
									border-color: $event-table-border-color !important;

									.fc-daygrid-day-number {
										color: $event-calendar-date-color !important;
									}

									.fc-day-today {
										background-color: $event-calendar-today-color !important;
									}

									.fc-daygrid-day-events {
										a {
											&.clickable-event {
												background-color: $event-calendar-clickable-event-bg-color !important;
												border-color: $event-calendar-clickable-event-border-color !important;
												border-left-color: $event-calendar-clickable-event-border-corder-color !important;
												border-right-color: $event-calendar-clickable-event-border-corder-color !important;

												&.holiday-event {
													background-color: transparent !important;
												}

												&:hover {
													background-color: $event-calendar-clickable-event-bg-hover-color !important;
												}

												.fc-title {
													color: $event-calendar-event-title-color !important;
												}

												.fc-event-main {
													color: $event-calendar-event-title-color !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.paging-heatmap-tools {
					.go-to-container {
						.go-to-label {
							color: $heatmap-paginator-text-color !important;
						}

						.mat-select-value {
							color: $heatmap-paginator-text-color !important;
						}

						.mat-select-arrow {
							color: $heatmap-paginator-text-color !important;
						}
					}
				}
			}
		}
	}

	.kt-footer {
		background-color: $footer-bg-color;

		.kt-footer__copyright {
			color: $footer-text-color;
		}
	}

	.dataset-labels-wrapper {
		background-color: $portlet-body-bg-color;
		color: white;
	}

	.loading-banner {
		background: rgba(29, 29, 29, 0.42) !important;
	}

	.heatmap {
		&.year-view {
			.day-column {
				background-color: #2a2a2a;

				.day-name {
					color: #bababa;
				}
			}

			.data-column {
				.month-name {
					color: #bababa;
				}
			}

			.title-column-container {
				.hotel-name {
					color: white;
				}

				.update-time {
					color: #75d2ee;
				}
			}

			.level-change {
				color: #ebebeb;
			}

			.level-total {
				color: white;
			}
		}

		&.horizontal-view {
			.sticky-header {
				background: $portlet-body-bg-color;
			}

			.day-column {
				color: #bababa;
			}

			.hotel-name {
				color: #fff;
			}

			.update-time {
				color: #75d2ee;
			}

			.level-change {
				color: white;
			}

			.level-total {
				color: white;
			}

			.title-column {
				background-color: $portlet-body-bg-color;
			}
		}

		&.calendar-view {
			.day-name {
				color: #bababa;
			}

			.level-change {
				color: white;
			}

			.level-total {
				color: white;
			}
		}

		.cell-container {

			&.zero-color {
				background-color: rgb(107, 99, 92);

				.cell-header {
					color: #bfbfbf;

					.fill-banner {
						background-color: #000000;
						opacity: 0.18;
					}
				}
			}

			&.overbooked-color {
				background-color: #25b1dc;

				.cell-header {
					color: white;

					.fill-banner {
						background-color: #000000;
						opacity: 0.18;
					}
				}
			}

			&.dark-red-color {
				background-color: #f92828;

				.cell-header {
					color: white;

					.fill-banner {
						background-color: #c20000;
						opacity: 0.2;
					}
				}
			}

			&.light-red-color {
				background-color: #ff7a7a;

				.cell-header {
					color: white;

					.fill-banner {
						background-color: #a50000;
						opacity: 0.2;
					}
				}
			}

			&.light-green-color {
				background-color: #81bd41;

				.cell-header {
					color: white;

					.fill-banner {
						background-color: #64c200;
						opacity: 0.6;
					}
				}
			}

			&.dark-green-color {
				background-color: #00a800;

				.cell-header {
					color: white;

					.fill-banner {
						background-color: #008d00;
						opacity: 0.5;
					}
				}
			}

			.value-container {
				color: #E5E5E5;

				&.no-color {
					.value-fill {
						background-color: rgb(72, 66, 62);
						opacity: 0.4;
					}

					.value-change {
						color: #E5E5E5;
					}
				}

				&.overbooked-color {
					.value-fill {
						background-color: #92e5ff;
						opacity: 0.3;
					}

					.value-change {
						color: #E5E5E5;
					}
				}

				&.dark-red-color {
					.value-fill {
						background-color: #A00000;
						opacity: 0.6;
					}

					.value-change {
						color: #E5E5E5;
					}
				}

				&.light-red-color {
					.value-fill {
						background-color: #ca2929;
						opacity: 0.5;
					}

					.value-change {
						color: #E5E5E5;
					}
				}

				&.light-green-color {
					.value-fill {
						background-color: #a5d176;
						opacity: 0.5;
					}

					.value-change {
						color: #E5E5E5;
					}
				}

				&.dark-green-color {
					.value-fill {
						background-color: #128e09;
						opacity: 0.5;
					}

					.value-change {
						color: #E5E5E5;
					}
				}
			}
		}
	}

	.kt-app__aside {
		@media only screen and (max-width: 1024px) {
			background-color: $portlet-body-bg-color !important;
		}

		.kt-widget {
			&.kt-widget--user-profile-1 {
				.kt-widget__body {
					.kt-widget__items {
						.kt-widget__item {
							&.kt-widget__item--active {
								background: $sidebar-link-active-bg-color;
							}

							&:hover {
								background: $sidebar-link-hover-bg-color;
							}
						}

						.sub-menu .kt-widget__item {
							&.kt-widget__item--active {
								background: rgba(255,255,255,0.03);
							}

							&:hover {
								background: rgba(255,255,255,0.03);
							}
						}
					}
				}
			}
		}
	}

	.list-group {
		&.ota-room-list {
			background-color: $mat-table-bg-color;

			.list-group-item {
				background-color: $mat-table-bg-color;
				color: $mat-table-text-color;
				border: 1px solid $mat-table-border-color;

				.mat-select-value {
					color: $mat-table-text-color;
				}

				.mat-select-arrow {
					color: $mat-table-text-color;
				}
			}
		}

		&.campaign-stat-list {
			color: $section-title-color !important;
		}
	}

	.hotel-setting-form {
		color: $form-field-text;

		.mat-select-value {
			color: $form-field-text;
		}

		.mat-form-field-label {
			color: $form-field-text;
		}

		.mat-select-arrow {
			color: $form-field-text;
		}
	}

	.badge-custom {
		background-color: $badge-custom-bg-color;
		color: $badge-custom-text-color;
	}

	.kt-app__aside-close {
		@media screen and (max-width: 1024px) {
			background-color: $mobile-menu-close-btn-bg-color;
			color: $mobile-menu-close-btn-icon-color;
		}
	}

	.btn-group {
		.btn {
			background-color: $group-btn-bg-color !important;
			border-color: $group-btn-border-color !important;
			color: $group-btn-text-color !important;

			&.active {
				background-color: $group-btn-active-bg-color !important;
			}

			&:hover {
				background-color: $group-btn-hover-bg-color !important;
			}
		}
	}

	.mobiscroll-container {
		border-color: $filter-section-input-border-color !important;

		.text-overlay {
			background-color: #1d1d1d !important;
			color: $filter-section-input-text-color !important;
		}
	}

	.search-filter {
		background-color: rgba(255, 255, 255, 0.1) !important;

		&:hover {
			background-color: rgba(0, 0, 0, 0.6) !important;
		}
	}

	.mat-stepper-horizontal {
		.mat-step-header {
			.mat-step-label {
				color: $stepper-header-label-color;

				&.mat-step-label-active {
					color: $stepper-header-label-active-color;
				}
			}

			.mat-step-icon {
				background-color: $stepper-header-step-icon-color;

				&.mat-step-icon-state-edit {
					background-color: $stepper-header-step-icon-edit-color;
				}
			}
		}

		.mat-horizontal-content-container {
			.stepper-form {
				label {
					color: $stepper-body-form-label-color;
				}

				.mat-input-element, .mat-select-value {
					color: $stepper-body-form-field-input-color;
				}

				.options-header {
					color: $stepper-body-form-field-input-color;
				}

				.mat-form-field-appearance-outline {
					.mat-form-field-outline {
						background-color: $stepper-body-form-field-bg-color;
						color: $stepper-body-form-field-outline-color;
						border-radius: 4px;
					}
				}

				.mat-checkbox-frame, .mat-radio-outer-circle {
					border-color: $stepper-body-form-checkbox-outline-color;
				}

				.mat-select-arrow {
					color: $stepper-body-form-select-arrow-color;
				}

				.mat-hint {
					color: $stepper-body-form-hint-color;
				}

				.date-section {
					> span {
						color: $stepper-body-form-hint-color;
					}

					.mat-select-value {
						color: $stepper-body-form-field-input-color;
					}
				}

				.mat-select-placeholder {
					color: $stepper-body-form-hint-color !important;
				}

				.header {
					color: $stepper-body-form-label-color;
				}

				.header-description {
					color: $stepper-body-form-hint-color;
				}

				.panel-group {
					border-color: $stepper-body-form-field-outline-color !important;

					.panel {
						.panel-heading {
							background-color: $panel-heading-bg-color;
							border-top-color: $stepper-body-form-field-outline-color;
							border-bottom-color: $stepper-body-form-field-outline-color;

							.text {
								color: $stepper-body-form-label-color;
							}
						}

						.panel-body {
							color: $stepper-body-form-hint-color;

							.mat-stroked-button {
								border-color: $outline-btn-border-color !important;
								color: $outline-btn-text-color !important;

								&:hover {
									color: $outline-btn-hover-text-color !important;
									background-color: $outline-btn-hover-color !important;
								}
							}

							.image-container {
								border-color: $stepper-body-form-field-outline-color !important;
							}
						}
					}
				}

				.event-section {
					span {
						color: $stepper-body-form-hint-color;
					}
				}
			}

			.template-card {
				background-color: $template-card-bg-color;

				.image {
					background-color: $template-card-img-bg-color;
				}

				.title {
					color: $template-card-title-color;
				}

				.type-title {
					color: $template-card-title-color;
				}

				.type-desc {
					color: $template-card-title-color;
				}

				.type-title {
					color: $template-card-title-color;
				}

				.type-desc {
					color: $template-card-title-color;
				}
			}

			.btn-outline-secondary {
				border-color: $outline-btn-border-color !important;
				color: $outline-btn-text-color !important;

				&:hover {
					color: $outline-btn-hover-text-color !important;
					background-color: $outline-btn-hover-color !important;
				}
			}

			.view-email {
				.frame-container {
					border-color: $stepper-body-form-field-outline-color;
				}
			}
		}
	}

	.guest-details-wrapper {
		color: $guest-details-default-text-color;

		.guest-info-header {
			.guest-heading {
				.guest-name {
					color: $block-titles-text-color !important;
				}
			}

			.guest-info-cards {
				color: $block-titles-text-color !important;

				.material-icons {
					color: $guest-details-icon-light-color;
				}
			}

			.card-container {
				.card-item {
					background-color: $guest-details-card-bg !important;
					border-color: $guest-details-card-border-color !important;

					.card-label {
						color: $guest-details-card-label-color;
					}

					.card-value {
						color: $block-titles-text-color;
					}
				}
			}
		}

		.kt-portlet__body {
			.guest-profile-data {
				.block {
					&.border-block {
						border-bottom-color: $guest-details-block-border-color !important;
					}

					.block-title {
						color: $block-titles-text-color;
					}

					.col-form-label {
						color: $guest-details-default-text-color !important;
					}

					.form-value {
						color: $guest-details-block-data-color !important;
					}

					.mat-chip {
						&.mat-standard-chip {
							color: $guest-details-badge-text-color !important;
							background-color: $guest-details-badge-bg-color !important;
						}
					}
				}
			}

			.guest-stay-data {
				.block-title {
					color: $block-titles-text-color !important;
				}

				.next-stay-block {
					ul.stay-dates {
						li {
							span.date {
								color: $block-titles-text-color;
							}
						}
					}

					.booking-details {
						.box-data {
							color: $block-titles-text-color;
						}
					}
				}
			}

			.guest-engagement-data {
				.block-title {
					color: $block-titles-text-color !important;
				}

				.data-table-container {
					.engagement-data-table {
						tr {
							th:first-child {
								color: $mat-table-text-color;
								border-bottom-color: $mat-table-border-color !important;
							}
						}

						tr {
							td {
								&:first-child {
									color: $mat-table-text-color;
									border-bottom-color: $mat-table-border-color !important;
									background-color: transparent !important;
								}
							}
						}
					}
				}
			}
		}
	}

	.custom-mat-select-panel {
		background-color: $mat-select-dropdown-bg-color;

		.mat-option-text {
			color: $stepper-body-form-field-input-color;
		}
	}

	kt-crm-create-subheader, kt-create-campaign-full-editor-dialog {
		.btn-grp {
			.btn-outline-secondary {
				border-color: $outline-btn-border-color !important;
				color: $outline-btn-text-color !important;

				&:hover {
					color: $outline-btn-hover-text-color !important;
					background-color: $outline-btn-hover-color !important;
				}
			}
		}
	}

	.budget-setting-form {
		mat-label {
			color: $form-lable-color !important;
		}

		.mat-select-value {
			color: $form-field-text !important;
		}

		.mat-select-arrow {
			color: $form-field-text !important;
		}
	}

	#kt_user_profile_aside {
		.campaign-credits {
			.status-text {
				color: $campaign-credit-text-color !important;
			}
		}
	}
}
