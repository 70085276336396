$header-bg-color: #e6dfd6;
$header-shadow-color: rgba(0,0,0,0.04);
$header-border-color: #eff0f6;
$header-topbar-border-color: #f3f0f0;
$menu-text-color: #5E6C84;
$menu-active-text-color: #5C66FF;
$menu-hover-text-color: #5C66FF;
$menu-active-bg-color: rgba(92,102,255,0.08);
$menu-hover-bg-color: rgba(92, 102, 255, 0.2);
$topbar-hover-bg-color: rgba(77,89,149,.06);
$topbar-username-color: #344563;
$topbar-username-badge-color: #9FAABB;
$topbar-username-badge-bg-color: #E8EAED;
$subheader-bg-color: #e6dfd6;
$subheader-text-color: #434349;
$subheader-dropdown-border-color: #E9EDF0;
$subheader-dropdown-bg-color: transparent;
$subheader-dropdown-hover-bg-color: rgba(77,89,149,.06);
$subheader-dropdown-shadow-color: rgba(0,0,0,0.04);
$subheader-dropdown-text-color: #344563;
$portlet-head-tab-text-color: #6c7293;
$portlet-head-tab-active-text-color: #5C66FF;
$portlet-head-dropdown-bg-color: transparent;
$portlet-head-dropdown-hover-bg-color: #eff0f5;
$portlet-head-dropdown-border-color: #dadada;
$portlet-head-dropdown-shadow-color: rgba(0,0,0,0.04);
$portlet-head-dropdown-text-color: #344563;
$portlet-body-bg-color: #FEFFFF;
$portlet-body-hover-bg-color:#e3f9ff;
$portlet-body-text-color: #646c9a;
$portlet-body-text-color-secondary: #808080;
$footer-bg-color: #e6dfd6;
$footer-text-color: #434349;
$chart-label-color: #6693d7;
$section-title-color: #464457;
$mobile-menu-close-btn-bg-color: #e8e8f2;
$mobile-menu-close-btn-icon-color: #968ad4;
$mat-table-bg-color: #FEFFFF;
$mat-table-text-color: rgba(0,0,0,0.95);
$mat-table-border-color: rgba(0,0,0,0.1);
$mat-table-th-bg-color: #f1f1f1;
$mat-table-th-text-color: rgba(0,0,0,0.95);
$mat-table-th-border-color: rgba(0,0,0,0.1);
$mat-table-bg-hover-color: #F5F7FF;
$mat-table-alternate-row-color: #F8F9FA;;
$mat-paginate-text-color: rgba(0,0,0,.54);
$form-lable-color: #000;
$form-field-text: rgba(0, 0, 0, 0.70);
$form-field-border-color: rgba(0, 0, 0, 0.5);
$sidebar-link-active-bg-color: rgba(0, 0, 0, 0.08);
$sidebar-link-hover-bg-color: rgba(0, 0, 0,0.08);
$badge-custom-bg-color: rgba(0,0,0,0.2);
$badge-custom-text-color: rgba(0,0,0,1);
$group-btn-bg-color: transparent;
$group-btn-active-bg-color: rgba(77, 89, 149, 0.1);
$group-btn-text-color: #344563;
$group-btn-border-color: #E9EDF0;
$form-control-bg-color: #fff;
$form-control-border-color: #E9EDF0;
$form-control-text-color: #344563;
$form-control-placeholder-color: #646c9a;
$form-control-icon-color: #646c9a;
$card-bg-color: #fff;
$card-header-bg-color: #f7f8fa;
$card-header-text-color: #646c9a;
$card-body-text-color: #646c9a;
$card-big-text-color: #5d78ff;
$card-icon-color: rgba(0, 0, 0, 0.3);
$card-border-color: #ebedf2;
$outline-btn-border-color: #e1e1ef;
$outline-btn-text-color: #494988;
$outline-btn-hover-color: #e1e1ef;
$outline-btn-hover-text-color: #494988;
$camp-card-bg-color: #fff;
$stepper-header-label-color: #6c7293;
$stepper-header-label-active-color: #5d78ff;
$stepper-header-step-icon-color: rgba(90,120,255,25%);
$stepper-header-step-icon-edit-color: #5d78ff;
$stepper-body-form-label-color: #6c7293;
$stepper-body-form-field-input-color: #6f77a2;
$stepper-body-form-field-bg-color: #fff;
$stepper-body-form-field-outline-color: rgba(90,120,255,25%);
$stepper-body-form-checkbox-outline-color: rgba(90,120,255,25%);
$stepper-body-form-select-arrow-color: #6f77a2;
$stepper-body-form-hint-color: #6f77a2;
$template-card-bg-color: #fff;
$template-card-img-bg-color: #f1f1f1;
$template-card-title-color: #6f77a2;
$panel-heading-bg-color: #eaeaea;
$guest-details-default-text-color: #5E6C84;
$block-titles-text-color: #434349;
$guest-details-icon-light-color: #a7abc3;
$guest-details-card-bg: rgba(255,255,255,1);
$guest-details-card-label-color: #5E6C84;
$guest-details-card-text-color: #5E6C84;
$guest-details-card-border-color: rgba(255,255,255,1);
$guest-details-block-border-color: rgba(0, 0, 0, 0.1);
$guest-details-block-data-color: #000;
$guest-details-badge-bg-color: rgba(0, 0, 0, 0.1);
$guest-details-badge-text-color: #5E6C84;
$guest-list-city-color: #7d7d7d;
$guest-list-bookingref-color: #5a5a5a;
$mat-select-dropdown-bg-color: #fff;
$sub-title-color: #5a5a5a;
$mat-primary-button-border-color: #5d78ff;
$mat-primary-button-text-color: #5d78ff;
$mat-primary-button-hover-bg-color: #5d78ff;
$mat-primary-button-hover-text-color: #fff;
$event-table-border-color: #D8D8D8;
$event-calendar-date-color: #5E6C84;
$event-calendar-clickable-event-bg-color: #EDEDEF;
$event-calendar-clickable-event-bg-hover-color: #d5d5d5;
$event-calendar-clickable-event-border-color: #ebedf2;
$event-calendar-clickable-event-border-corder-color: rgba(0, 0, 0, 0.2);
$event-calendar-event-title-color: #6c7293;
$event-nextprev-btn-text-color: #344563;
$event-nextprev-btn-bg-color: transparent;
$event-nextprev-btn-hover-bg-color: rgba(77, 89, 149, 0.06);
$event-nextprev-btn-border-color: #E9EDF0;
$event-calendar-today-color: #c0eeff96;
$campaign-credit-text-color: #646c9a;
$heatmap-paginator-text-color: rgba(0,0,0,.54);

body {
	&.yellow-theme {
		background-color: #f3efea;
	}
}

.yellow-theme {

	::placeholder {
		color: $form-control-placeholder-color !important;
		opacity: 1;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: $form-control-placeholder-color !important;
		opacity: 1;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: $form-control-placeholder-color !important;
	}


	.kt-header {
		border-bottom-color: $header-border-color;
		background-color: $header-bg-color;
		box-shadow: 0 25px 50px 0 $header-shadow-color;

		.kt-header-menu-wrapper-close {
			background-color: $mobile-menu-close-btn-bg-color;

			> i {
				color: $mobile-menu-close-btn-icon-color;
			}
		}

		.kt-header-menu-wrapper {
			@media only screen and (max-width: 1024px) {
				background-color: $header-bg-color;
			}

			.kt-header-menu {

				&.kt-header-menu-mobile {
					background-color: $header-bg-color;

					.kt-menu__nav {
						> .kt-menu__item {
							&:not(.kt-menu__item--parent) {
								&:not(.kt-menu__item--open) {
									&:not(.kt-menu__item--here) {
										&:not(.kt-menu__item--active) {
											&:hover {
												> .kt-menu__heading {
													background-color: $menu-active-bg-color;
												}

												> .kt-menu__link {
													background-color: $menu-active-bg-color;
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.kt-menu__nav {

					> .kt-menu__item {

						> .kt-menu__link {

							> .kt-menu__link-text {
								color: $menu-text-color;

								&.copilot {
									color: $menu-active-text-color;
								}
							}
						}

						&.kt-menu__item--active {

							> .kt-menu__link {
								background-color: $menu-active-bg-color;

								.kt-menu__link-text {
									color: $menu-active-text-color;
									text-shadow: -0.06ex 0 $menu-active-text-color, 0.06ex 0 $menu-active-text-color;
								}
							}
						}

						&:hover {

							> .kt-menu__link {
								background-color: $menu-hover-bg-color;

								.kt-menu__link-text {
									color: $menu-hover-text-color;
									text-shadow: -0.06ex 0 $menu-active-text-color, 0.06ex 0 $menu-active-text-color;
								}
							}
						}
					}
				}
			}
		}

		.kt-header__topbar {
			.kt-header__topbar-item {
				&.kt-header__topbar-item--user {
					.kt-header__topbar-user {
						.kt-badge.kt-badge--username {
							background-color: $topbar-username-badge-bg-color;
							color: $topbar-username-badge-color;
						}

						.kt-header__topbar-username {
							color: $topbar-username-color !important;
						}

						&::after {
							color: $topbar-username-color !important;
						}
					}
				}

				.kt-header__topbar-user {
					padding: 5px 8px;

					&:hover {
						background-color: $topbar-hover-bg-color !important;
					}
				}
			}
		}
	}

	.kt-header-mobile {
		background-color: $header-bg-color;
	}

	.kt-subheader {
		background-color: $subheader-bg-color !important;

		.kt-subheader__title {
			color: $subheader-text-color !important;
		}

		.kt-subheader__desc {
			color: $subheader-text-color !important;
		}

		.kt-subheader__breadcrumbs a {
			color: #5C66FF !important;
			font-weight: bold;

			&:hover {
				color: #7a82ff !important;
			}
		}

		.kt-subheader__breadcrumbs-home:hover {
			color: $menu-hover-text-color !important;
		}

		.kt-subheader__toolbar {

			.kt-subheader__wrapper {

				.dropdown {
					border-color: $subheader-dropdown-border-color;
					box-shadow: 0 2px 6px 0 $subheader-dropdown-shadow-color;
					background-color: $subheader-dropdown-bg-color;

					@media (hover: hover) {
						&:hover {
							background-color: $subheader-dropdown-hover-bg-color;
						}
					}

					> button {
						color: $subheader-dropdown-text-color;

						> span {
							color: $subheader-dropdown-text-color;
						}

						> .flaticon2-calendar-1 {
							color: $subheader-dropdown-text-color;
						}
					}

					.form-control {
						background-color: #fff !important;
						border-color: #e2e5ec !important;
						color: #495057 !important;

						&:focus {
							border-color: #9aabff !important;
						}
					}
				}
			}

			.form-control {
				background-color: $form-control-bg-color !important;
				border-color: $form-control-border-color !important;
				color: $form-control-text-color !important;
			}

			.search-icon {
				.flaticon2-search-1 {
					color: $form-control-icon-color !important;
				}
			}

			.clear-icon {
				.flaticon2-cross {
					color: $form-control-icon-color !important;
				}
			}

			.btn {
				&.btn-prev, &.btn-nxt {
					background-color: $event-nextprev-btn-bg-color !important;
					color: $event-nextprev-btn-text-color !important;
					border-color: $event-nextprev-btn-border-color !important;

					&:hover {
						background-color: $event-nextprev-btn-hover-bg-color !important;
					}
				}
			}
		}
	}

	.kt-content {
		.kt-portlet {
			.kt-portlet__head {
				.nav-tabs {
					&.nav-tabs-line {
						a.nav-link {
							color: $portlet-head-tab-text-color;

							&.active, &:hover {
								color: $portlet-head-tab-active-text-color;
								text-shadow: -0.06ex 0 $portlet-head-tab-active-text-color, 0.06ex 0 $portlet-head-tab-active-text-color;
							}
						}

						&.nav.nav-tabs {
							.nav-link {
								color: $portlet-head-tab-text-color;

								&.active, &:hover {
									color: $portlet-head-tab-active-text-color;
									text-shadow: -0.06ex 0 $portlet-head-tab-active-text-color, 0.06ex 0 $portlet-head-tab-active-text-color;
								}
							}
						}
					}
				}

				.kt-portlet__head-toolbar {
					.dropdown {
						background-color: $portlet-head-dropdown-bg-color;
						box-shadow: 0 2px 6px 0 $portlet-head-dropdown-shadow-color;
						color: $portlet-head-dropdown-text-color;

						&:hover {
							background-color: $portlet-head-dropdown-hover-bg-color;
						}

						.btn-outline-brand:hover {
							color: white;
						}

						.btn-secondary {
							border-color: $portlet-head-dropdown-border-color;
						}

						> button {
							color: $portlet-head-dropdown-text-color;
						}

						&.show {
							> button.btn-outline-brand {
								color: white;
							}
						}
					}

					.mobiscroll-elem {
						.update-picker {
							color: $portlet-head-dropdown-text-color;
							background-color: $portlet-head-dropdown-bg-color;
							border-color: $portlet-head-dropdown-border-color;
							box-shadow: 0 2px 6px 0 $portlet-head-dropdown-shadow-color;

							&:hover {
								background-color: $portlet-head-dropdown-hover-bg-color;
							}
						}

						&::after {
							color: $portlet-head-dropdown-text-color;
						}
					}
				}

				.kt-portlet__head-label {
					h3.kt-portlet__head-title {
						color: $section-title-color !important;
					}
				}
			}

			.kt-portlet__body {
				background-color: $portlet-body-bg-color;

				h3 {
					color: $section-title-color !important;
				}

				.mat-table {
					thead {
						tr {
							background-color: $mat-table-th-bg-color !important;

							th {
								background-color: transparent !important;
							}
						}
					}

					tbody {
						tr {
							&:hover {
								background-color: $mat-table-bg-hover-color !important;
							}

							&:nth-child(even) {
								background-color: $mat-table-alternate-row-color;
							}
						}
					}
				}

				.guest-table, .overview-guest-table {
					.mat-col-guest {
						.guest-name {
							small {
								&.guest-city {
									color: $guest-list-city-color !important;
								}

								&.guest-bookingref {
									color: $guest-list-bookingref-color !important;
								}
							}
						}
					}
				}

				.mat-paginator {
					.mat-select-value {
						color: $mat-paginate-text-color;
					}

					.mat-select-arrow {
						color: $mat-paginate-text-color;
					}
				}

				.guest-filter-options, .segment-searchm, .campaign-filter-options {

					.form-control {
						background-color: $form-control-bg-color !important;
						border-color: $form-control-border-color !important;
						color: $form-control-text-color !important;
					}

					.search-icon {
						.flaticon2-search-1 {
							color: $form-control-icon-color !important;
						}
					}

					.clear-icon {
						.flaticon2-cross {
							color: $form-control-icon-color !important;
						}
					}

					.btn-datepicker-trigger {
						background-color: $form-control-bg-color !important;
						border-color: $form-control-border-color !important;
						color: $form-control-text-color !important;

						.flaticon2-calendar-1 {
							color: $form-control-icon-color !important;
						}

						span.placeholder-text {
							color: $form-control-placeholder-color !important;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}

				.top-data-info {
					color: $portlet-body-text-color-secondary !important;
				}

				.card {
					border-color: $card-border-color !important;
					background-color: $card-bg-color !important;

					.card-header {
						background-color: $card-header-bg-color;
						border-bottom-color: $card-border-color !important;

						h4 {
							color: $card-header-text-color !important;
						}
					}

					.card-body {
						color: $card-body-text-color !important;

						.fa {
							color: $card-icon-color !important;
						}

						.big-font {
							color: $card-big-text-color !important;
						}

						.card-property-list {
							border-top-color: $card-border-color !important;
							border-bottom-color: $card-border-color !important;
						}

						.exclude-bookings {
							border-top-color: $card-border-color !important;
						}
					}
				}

				.campaign-type-card {
					background-color: $camp-card-bg-color !important;
				}

				.mat-table {
					background-color: $mat-table-bg-color;

					.mat-header-cell {
						background-color: transparent !important;
						color: $mat-table-th-text-color;
						border-bottom-color: $mat-table-th-border-color;
					}

					.mat-cell {
						color: $mat-table-text-color;
						border-bottom-color: $mat-table-border-color;
					}
				}

				.no-results {
					color: $portlet-body-text-color;
				}

				.statistics-container {
					> span {
						color: $portlet-body-text-color;
					}
				}

				&.onKpiChartHover:hover, .kpi-content-hover:hover {
					background-color: $portlet-body-hover-bg-color;
					cursor: pointer;
				}

				.chart-title {
					.subtitle {
						color: $sub-title-color !important;
					}
				}

				.border-right {
					border-right-color: $mat-table-border-color !important;
				}

				.mat-stroked-button {
					border-color: $mat-primary-button-border-color !important;

					&.mat-primary {
						color: $mat-primary-button-text-color !important;
					}

					&:hover {
						background-color: $mat-primary-button-hover-bg-color !important;

						&.mat-primary {
							color: $mat-primary-button-hover-text-color !important;
						}
					}
				}

				.fc-scrollgrid {
					border-color: $event-table-border-color !important;
				}

				.fc-daygrid {
					table {
						thead {
							tr {

								table.fc-col-header {
									th {
										border-color: $event-table-border-color !important;
										color: $event-calendar-date-color !important;
									}
								}
							}
						}

						tbody {
							tr {
								td {
									border-color: $event-table-border-color !important;

									.fc-daygrid-day-number {
										color: $event-calendar-date-color !important;
									}

									.fc-day-today {
										background-color: $event-calendar-today-color !important;
									}

									.fc-daygrid-day-events {
										a {
											&.clickable-event {
												background-color: $event-calendar-clickable-event-bg-color !important;
												border-color: $event-calendar-clickable-event-border-color !important;
												border-left-color: $event-calendar-clickable-event-border-corder-color !important;
												border-right-color: $event-calendar-clickable-event-border-corder-color !important;

												&.holiday-event {
													background-color: transparent !important;
												}

												&:hover {
													background-color: $event-calendar-clickable-event-bg-hover-color !important;
												}

												.fc-title {
													color: $event-calendar-event-title-color !important;
												}

												.fc-event-main {
													color: $event-calendar-event-title-color !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}

				.paging-heatmap-tools {
					.go-to-container {
						.go-to-label {
							color: $heatmap-paginator-text-color !important;
						}

						.mat-select-value {
							color: $heatmap-paginator-text-color !important;
						}

						.mat-select-arrow {
							color: $heatmap-paginator-text-color !important;
						}
					}
				}
			}
		}
	}

	.kt-footer {
		background-color: $footer-bg-color;

		.kt-footer__copyright {
			color: $footer-text-color;
		}
	}

	.dataset-labels-wrapper {
		background-color: #ffffff;
		color: #6693d7;
	}

	.loading-banner {
		background: rgba(255,255,255,0.4) !important;
	}

	.kt-app__aside {
		@media only screen and (max-width: 1024px) {
			background-color: $portlet-body-bg-color !important;
		}

		.kt-widget {
			&.kt-widget--user-profile-1 {
				.kt-widget__body {
					.kt-widget__items {
						.kt-widget__item {
							&.kt-widget__item--active {
								background: $sidebar-link-active-bg-color;
							}

							&:hover {
								background: $sidebar-link-hover-bg-color;
							}
						}

						.sub-menu .kt-widget__item {
							&.kt-widget__item--active {
								background: rgba(0,0,0,0.03);
							}

							&:hover {
								background: rgba(0,0,0,0.03);
							}
						}
					}
				}
			}
		}
	}

	.list-group {
		&.ota-room-list {
			background-color: $mat-table-bg-color;

			.list-group-item {
				background-color: $mat-table-bg-color;
				color: $mat-table-text-color;
				border: 1px solid $mat-table-border-color;

				.mat-select-value {
					color: $mat-table-text-color;
				}

				.mat-select-arrow {
					color: $mat-table-text-color;
				}
			}
		}

		&.campaign-stat-list {
			color: $section-title-color !important;
		}
	}

	.hotel-setting-form {
		color: $form-lable-color;

		.mat-select-value {
			color: $form-field-text;
		}

		.mat-form-field-label {
			color: $form-field-text;
		}

		.mat-select-arrow {
			color: $form-field-text;
		}
	}

	.badge-custom {
		background-color: $badge-custom-bg-color;
		color: $badge-custom-text-color;
	}

	.kt-app__aside-close {
		@media screen and (max-width: 1024px) {
			background-color: $mobile-menu-close-btn-bg-color;
			color: $mobile-menu-close-btn-icon-color;
		}
	}

	.btn-group {
		.btn {
			background-color: $group-btn-bg-color !important;
			border-color: $group-btn-border-color !important;
			color: $group-btn-text-color !important;

			&.active {
				background-color: $group-btn-active-bg-color !important;
			}

			&:hover {
				background-color: $group-btn-active-bg-color !important;
			}
		}
	}

	.mat-stepper-horizontal {

		.mat-horizontal-content-container {
			.stepper-form {
				label {
					color: $stepper-body-form-label-color;
				}

				.mat-input-element, .mat-select-value {
					color: $stepper-body-form-field-input-color;
				}

				.options-header {
					color: $stepper-body-form-field-input-color;
				}

				.mat-form-field-appearance-outline {
					.mat-form-field-outline {
						background-color: $stepper-body-form-field-bg-color;
						color: $stepper-body-form-field-outline-color;
						border-radius: 4px;
					}
				}

				.mat-checkbox-frame, .mat-radio-outer-circle {
					border-color: $stepper-body-form-checkbox-outline-color;
				}

				.mat-select-arrow {
					color: $stepper-body-form-select-arrow-color;
				}

				.mat-hint {
					color: $stepper-body-form-hint-color;
				}

				.date-section {
					> span {
						color: $stepper-body-form-hint-color;
					}

					.mat-select-value {
						color: $stepper-body-form-field-input-color;
					}
				}

				.mat-select-placeholder {
					color: $stepper-body-form-hint-color !important;
				}

				.header {
					color: $stepper-body-form-label-color;
				}

				.header-description {
					color: $stepper-body-form-hint-color;
				}

				.panel-group {
					border-color: $stepper-body-form-field-outline-color !important;

					.panel {
						.panel-heading {
							background-color: $panel-heading-bg-color;
							border-top-color: $stepper-body-form-field-outline-color;
							border-bottom-color: $stepper-body-form-field-outline-color;

							.text {
								color: $stepper-body-form-label-color;
							}
						}

						.panel-body {
							color: $stepper-body-form-hint-color;

							.mat-stroked-button {
								border-color: $outline-btn-border-color !important;
								color: $outline-btn-text-color !important;

								&:hover {
									color: $outline-btn-hover-text-color !important;
									background-color: $outline-btn-hover-color !important;
								}
							}

							.image-container {
								border-color: $stepper-body-form-field-outline-color !important;
							}
						}
					}
				}

				.event-section {
					span {
						color: $stepper-body-form-hint-color;
					}
				}
			}

			.template-card {
				background-color: $template-card-bg-color;

				.image {
					background-color: $template-card-img-bg-color;
				}

				.title {
					color: $template-card-title-color;
				}

				.type-title {
					color: $template-card-title-color;
				}

				.type-desc {
					color: $template-card-title-color;
				}
			}

			.btn-outline-secondary {
				border-color: $outline-btn-border-color !important;
				color: $outline-btn-text-color !important;

				&:hover {
					color: $outline-btn-hover-text-color !important;
					background-color: $outline-btn-hover-color !important;
				}
			}
		}
	}

	.guest-details-wrapper {
		color: $guest-details-default-text-color;

		.guest-info-header {
			.guest-heading {
				.guest-name {
					color: $block-titles-text-color !important;
				}
			}

			.guest-info-cards {
				color: $block-titles-text-color !important;

				.material-icons {
					color: $guest-details-icon-light-color;
				}
			}

			.card-container {
				.card-item {
					background-color: $guest-details-card-bg !important;
					border-color: $guest-details-card-border-color !important;

					.card-label {
						color: $guest-details-card-label-color;
					}

					.card-value {
						color: $block-titles-text-color;
					}
				}
			}
		}

		.kt-portlet__body {
			.guest-profile-data {
				.block {
					&.border-block {
						border-bottom-color: $guest-details-block-border-color !important;
					}

					.block-title {
						color: $block-titles-text-color;
					}

					.col-form-label {
						color: $guest-details-default-text-color !important;
					}

					.form-value {
						color: $guest-details-block-data-color !important;
					}

					.mat-chip {
						&.mat-standard-chip {
							color: $guest-details-badge-text-color !important;
							background-color: $guest-details-badge-bg-color !important;
						}
					}
				}
			}

			.guest-stay-data {
				.block-title {
					color: $block-titles-text-color !important;
				}

				.next-stay-block {
					ul.stay-dates {
						li {
							span.date {
								color: $block-titles-text-color;
							}
						}
					}

					.booking-details {
						.box-data {
							color: $block-titles-text-color;
						}
					}
				}
			}

			.guest-engagement-data {
				.block-title {
					color: $block-titles-text-color !important;
				}

				.data-table-container {
					.engagement-data-table {
						tr {
							th:first-child {
								color: $mat-table-text-color;
								border-bottom-color: $mat-table-border-color !important;
							}
						}

						tr {
							td:first-child {
								color: $mat-table-text-color;
							}
						}
					}
				}
			}

			.nav-tabs {
				&.nav-tabs-line {
					a.nav-link {
						color: $portlet-head-tab-text-color;

						&.active, &:hover {
							color: $portlet-head-tab-active-text-color;
						}
					}

					&.nav.nav-tabs {
						.nav-link {
							color: $portlet-head-tab-text-color;

							&.active, &:hover {
								color: $portlet-head-tab-active-text-color;
							}
						}
					}
				}
			}
		}
	}

	.custom-mat-select-panel {
		background-color: $mat-select-dropdown-bg-color;

		.mat-option-text {
			color: $stepper-body-form-field-input-color;
		}
	}

	kt-crm-create-subheader, kt-create-campaign-full-editor-dialog {
		.btn-grp {
			.btn-outline-secondary {
				border-color: $outline-btn-border-color !important;
				color: $outline-btn-text-color !important;

				&:hover {
					color: $outline-btn-hover-text-color !important;
					background-color: $outline-btn-hover-color !important;
				}
			}
		}
	}

	.budget-setting-form {
		mat-label {
			color: $form-lable-color !important;
		}

		.mat-select-value {
			color: $form-field-text !important;
		}

		.mat-select-arrow {
			color: $form-field-text !important;
		}
	}

	#kt_user_profile_aside {
		.campaign-credits {
			.status-text {
				color: $campaign-credit-text-color !important;
			}
		}
	}
}
