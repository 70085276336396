.d3-tip {
	z-index: 99;
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.4;
	padding: 6px;
	pointer-events: none !important;
	color: #203d5d;
	box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
	background-color: #fff;
	border-radius: 4px;
}
	/* Creates a small triangle extender for the tooltip */

	.d3-tip:after {
		box-sizing: border-box;
		display: inline;
		font-size: 10px;
		width: 100%;
		line-height: 1;
		color: #fff;
		position: absolute;
		pointer-events: none;
		pointer-events: none !important;
	}
	/* Northward tooltips */

	.d3-tip.n:after {
		content: "\25BC";
		margin: -2px 0 0 0;
		top: 100%;
		left: 0;
		text-align: center;
		pointer-events: none !important;
	}
	/* Eastward tooltips */

	.d3-tip.e:after {
		content: "\25C0";
		margin: -4px 0 0 0;
		top: 50%;
		left: -7px;
		pointer-events: none !important;
	}
	/* Southward tooltips */

	.d3-tip.s:after {
		content: "\25B2";
		margin: 0 0 1px 0;
		top: -7px;
		left: 0;
		text-align: center;
		pointer-events: none !important;
	}
	/* Westward tooltips */

	.d3-tip.w:after {
		content: "\25B6";
		margin: -4px 0 0 -1px;
		top: 50%;
		left: 100%;
		pointer-events: none !important;
	}

.d3-tip-bubble {
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.4;
	padding: 12px;
	pointer-events: none !important;
	color: white;
	box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
	background-color: #FFB70F;
	border-radius: 4px;
	pointer-events: none !important;
}
	/* Creates a small triangle extender for the tooltip */

	.d3-tip-bubble:after {
		box-sizing: border-box;
		display: inline;
		font-size: 10px;
		width: 100%;
		line-height: 1;
		color: #fff;
		position: absolute;
		pointer-events: none;
		pointer-events: none !important;
	}
	/* Northward tooltips */

	.d3-tip-bubble.n:after {
		content: "\25BC";
		margin: -1px 0 0 0;
		top: 100%;
		left: 0;
		text-align: center;
		pointer-events: none !important;
	}
	/* Eastward tooltips */

	.d3-tip-bubble.e:after {
		content: "\25C0";
		margin: -4px 0 0 0;
		top: 50%;
		left: -8px;
		pointer-events: none !important;
	}
	/* Southward tooltips */

	.d3-tip-bubble.s:after {
		content: "\25B2";
		margin: 0 0 1px 0;
		top: -8px;
		left: 0;
		pointer-events: none !important;
		text-align: center;
	}
	/* Westward tooltips */

	.d3-tip-bubble.w:after {
		content: "\25B6";
		margin: -4px 0 0 -1px;
		top: 50%;
		pointer-events: none !important;
		left: 100%;
	}
